import Skeleton from "react-loading-skeleton";
import {IAppliedFilter, IFilterConfig} from "@/app/models/auctor";
import AppTooltipWrapper from "@/app/components/atomic/molecules/AppTooltipWrapper";
import dynamic from "next/dynamic";
import {SelectFilterSkeleton} from "@/app/components/auctor/filter/FilterSelect";
import FilterSelect from "@/app/components/auctor/filter/FilterSelect";

const FilterSlider = dynamic(() => import("@/app/components/auctor/filter/FilterSlider"), {ssr: false});
const FilterNumber = dynamic(() => import("@/app/components/auctor/filter/FilterNumber"), {ssr: false});
const FilterToggle = dynamic(() => import("@/app/components/auctor/filter/FilterToggle"), {ssr: false});
const FilterDate = dynamic(() => import("@/app/components/auctor/filter/FilterDate"), {
    ssr: false,
    loading: () => <SelectFilterSkeleton />
});
const FilterCountrySelect = dynamic(() => import("@/app/components/auctor/filter/FilterCountrySelect"), {
    ssr: false,
    loading: () => <SelectFilterSkeleton />
});

export const FILTER_TYPE_SELECT = 'select';
export const FILTER_TYPE_SLIDER = 'slider';
export const FILTER_TYPE_NUMBER = 'number';
export const FILTER_TYPE_TEXT = 'text';
export const FILTER_TYPE_TOGGLE = 'toggle';
export const FILTER_TYPE_DATE = 'date';
export const FILTER_TYPE_COUNTRY = 'country';

export const FILTER_TYPES = [
    FILTER_TYPE_SELECT,
    FILTER_TYPE_SLIDER,
    FILTER_TYPE_NUMBER,
    FILTER_TYPE_TOGGLE,
    FILTER_TYPE_TEXT,
    FILTER_TYPE_DATE,
    FILTER_TYPE_COUNTRY,
];

export default function Filter({filterConfig, appliedFilter, updateAppliedFilter}: {
    filterConfig: IFilterConfig,
    appliedFilter: IAppliedFilter,
    updateAppliedFilter: (filter: IAppliedFilter) => void
}) {

    return (
        <>
            <div
                className={`flex ${filterConfig.one_line_label ? 'flex-row items-center gap-4' : 'flex-col gap-1'} w-full`}
            >
                {filterConfig.label !== null &&
                    <div className="flex md:basis-3/5 basis-1/2">
                        {filterConfig.tooltip &&
                            <AppTooltipWrapper
                                id={String(filterConfig.unique_id)}
                                text={filterConfig.tooltip}
                                iconClassName={"fa-info-circle"}
                            >
                                <Label filterConfig={filterConfig} />
                            </AppTooltipWrapper>
                        }

                        {!filterConfig.tooltip &&
                            <>
                                <Label filterConfig={filterConfig} />
                            </>
                        }
                    </div>
                }

                <div className="flex md:basis-2/5 basis-1/2">
                    {filterConfig.type === FILTER_TYPE_SELECT &&
                        <FilterSelect
                            filterConfig={filterConfig}
                            appliedFilter={appliedFilter}
                            handleChange={updateAppliedFilter}
                        />
                    }

                    {filterConfig.type === FILTER_TYPE_SLIDER &&
                        <FilterSlider
                            filterConfig={filterConfig}
                            appliedFilter={appliedFilter}
                            handleChange={updateAppliedFilter}
                        />
                    }

                    {filterConfig.type === FILTER_TYPE_NUMBER &&
                        <FilterNumber
                            filterConfig={filterConfig}
                            appliedFilter={appliedFilter}
                            handleChange={updateAppliedFilter}
                        />
                    }

                    {filterConfig.type === FILTER_TYPE_TOGGLE &&
                        <FilterToggle
                            filterConfig={filterConfig}
                            appliedFilter={appliedFilter}
                            handleChange={updateAppliedFilter}
                        />
                    }

                    {filterConfig.type === FILTER_TYPE_DATE &&
                        <FilterDate
                            filterConfig={filterConfig}
                            appliedFilter={appliedFilter}
                            handleChange={updateAppliedFilter}
                        />
                    }

                    {filterConfig.type === FILTER_TYPE_COUNTRY &&
                        <FilterCountrySelect
                            filterConfig={filterConfig}
                            appliedFilter={appliedFilter}
                            handleChange={updateAppliedFilter}
                        />
                    }
                </div>
            </div>
        </>
    )
}

function Label({filterConfig}: { filterConfig: IFilterConfig }) {
    return (
        <label
            htmlFor={filterConfig.variable_name}
            className={"text-blue-700 font-semibold"}
        >
            {filterConfig.label_icon?.includes('fa-') &&
                <span className={"mr-2 fas " + filterConfig.label_icon} />
            }

            {filterConfig.label}
        </label>
    )
}

export function FilterSkeleton({className}: { className?: string }) {
    return (
        <>
            <div
                className={`flex flex-col gap-1 ${className}`}
            >
                <Skeleton
                    width={80}
                    height={20}
                />
                <Skeleton
                    height={38}
                />
            </div>
        </>
    )
}
