import {IAppliedFilter, IFeaturedOfferConfig, IFilterConfig} from "@/app/models/auctor";
import {
    evaluateExpressionWithFilters,
    evaluateExpressionWithFiltersAndRowData,
    populateExpressionWithAppliedFilters,
    prepareExpression
} from "@/app/utils/expression-utils";

export const getFilterDisplayCondition = (filter: any, appliedFilters: IAppliedFilter[]) => {
    if (!filter.display_condition) {
        return true
    }

    try {
        let condition = filter.display_condition

        condition = prepareExpression(condition)

        condition = populateExpressionWithAppliedFilters(condition, appliedFilters)

        const expressionFunction = new Function('appliedFilters', condition)

        return expressionFunction(appliedFilters)
    } catch (error) {
        console.info(`Error evaluating display condition for filter ${filter.variableName}:`, error)
        return false
    }
}

export const parseDefaultValueForFilterType = (filter: any, defaultPageValue?: any) => {
    if (filter.type === 'select') {
        let defaultSelectValue = null
        if (!defaultPageValue) {
            defaultSelectValue = filter.options.find((option: any) => option.is_default)
        } else {
            defaultSelectValue = filter.options.find((option: any) => option.value === defaultPageValue)
        }

        if (!filter.is_multiple) {
            return defaultSelectValue
        }

        return defaultSelectValue ? [defaultSelectValue] : null
    }

    return defaultPageValue ?? filter.default_value
}

export const toNumber = (value: any) => {
    if (value !== undefined && value !== null && typeof value !== 'number') {
        return parseFloat(value.toString().replace(/\s/g, ''));
    }

    return value;
}

export const getFilterDefaultValueForPage = (filter: any, pageConfig?: any) => {
    const currentPageConfig = filter.page_configs.find((config: any) => config?.page?.id === pageConfig?.auctorPageId)

    return parseDefaultValueForFilterType(filter, currentPageConfig?.default_value)
}

export const valueMatchFilterConstraint = (value: any, filterValue: any, constraint: any) => {
    const numberConstraints = ['greater_than', 'lower_than']

    // preparse number values before applying constraints
    if (numberConstraints.includes(constraint)) {
        value = toNumber(value)
        filterValue = toNumber(filterValue)
    }

    switch (constraint) {
        case 'equals':
            return value == filterValue
        case 'not_equals':
            return value != filterValue
        case 'greater_than':
            return value > filterValue
        case 'lower_than':
            return value < filterValue
        case 'contains':
            return value.includes(filterValue)
        case 'not_contains':
            return !value.includes(filterValue)
        default:
            return true
    }
}

export const rowMatchFilterConstraint = (row: any, appliedFilter: IAppliedFilter) => {
    if (appliedFilter.value === null || appliedFilter.value === undefined) {
        return true
    }

    const valueToCheck = row[appliedFilter.targetColumn]
    let filterValue = appliedFilter.value

    if (appliedFilter.type !== 'select') {
        return valueMatchFilterConstraint(valueToCheck, filterValue, appliedFilter.filterConstraint)
    }

    if (!appliedFilter.isMultiple) {
        // @ts-ignore because we know it's an object if we're in a select single, and we know it has a value property
        filterValue = appliedFilter.value?.value
        return valueMatchFilterConstraint(valueToCheck, filterValue, appliedFilter.filterConstraint)
    }

    if (appliedFilter.isStrictMultiple) {
        if (appliedFilter.value.length === 0) {
            return true
        }

        // @ts-ignore because we know it's an array if we're in a select multiple
        return appliedFilter.value.every(
            (selectedValue: any) => valueMatchFilterConstraint(
                valueToCheck,
                selectedValue.value,
                appliedFilter.filterConstraint,
            ),
        )
    }

    if (!appliedFilter.isStrictMultiple) {
        if (appliedFilter.value.length === 0) {
            return true
        }

        // @ts-ignore because we know it's an array if we're in a select multiple
        return appliedFilter.value.some(
            (selectedValue: any) => valueMatchFilterConstraint(
                valueToCheck,
                selectedValue.value,
                appliedFilter.filterConstraint,
            ),
        )
    }

    return true
}

export const initAppliedFilters = (filtersConfig: IFilterConfig[], pageConfig?: any) => {
    if (!filtersConfig) {
        return []
    }

    return filtersConfig?.map((filter: any) => {
        const defaultValue = getFilterDefaultValueForPage(filter, pageConfig)

        return {
            filterId: filter.unique_id,
            targetColumn: filter.target_column,
            type: filter.type,
            filterConstraint: filter.filter_constraint,
            value: defaultValue,
            isMultiple: filter.is_multiple,
            isStrictMultiple: filter.is_strict_multiple,
            variableName: filter.variable_name,
            dynamicValueExpression: filter.dynamic_value_expression,
        }
    })
}

export const applyFiltersOnResults = (results: any, filtersConfig: IFilterConfig[], appliedFilters: IAppliedFilter[], expressionFilter: string | null | undefined) => {
    return results?.filter((row: any) => {
        let available = true
        for (const filter of appliedFilters) {
            const filterConfig = filtersConfig.find((f: IFilterConfig) => f.unique_id === filter.filterId)

            if (filterConfig && filterConfig.display_condition) {
                const shouldDisplayFilter = getFilterDisplayCondition(filterConfig, appliedFilters)
                if (shouldDisplayFilter === false) {
                    continue
                }
            }

            const rowMatch = rowMatchFilterConstraint(row, filter)

            if (!rowMatch) {
                available = false
                break
            }
        }

        // handle expression filter
        if (expressionFilter) {
            const isOfferMatchingExpression = evaluateExpressionWithFiltersAndRowData(expressionFilter, row, appliedFilters)

            if (!isOfferMatchingExpression) {
                available = false
            }
        }

        return available
    })
}


export const sortBetweenFeaturedAndNormalResults = (results: any[], appliedFilters: IAppliedFilter[], featuredOffersConfig?: IFeaturedOfferConfig[]) => {
    const featuredResults: any[] = []
    if (featuredOffersConfig) {
        featuredOffersConfig.forEach((featuredOfferConfig: any) => {
            // check if the featured rule appliance condition is met
            const featuredRuleApplianceCondition = featuredOfferConfig.application_condition_expression
            const shouldFeaturedRuleApply = evaluateExpressionWithFilters(featuredRuleApplianceCondition, appliedFilters)

            if (shouldFeaturedRuleApply) {
                // apply the featured offer selection expression
                results.forEach((result: any) => {
                    const isOfferFeatured = evaluateExpressionWithFiltersAndRowData(
                        featuredOfferConfig.offer_selection_expression,
                        result,
                        appliedFilters
                    )

                    if (isOfferFeatured) {
                        // remove the result from the results array
                        const index = results.indexOf(result)
                        if (index > -1) {
                            results.splice(index, 1)
                        }

                        featuredResults.push({
                            ...result,
                            isFeatured: true,
                        })
                    }
                })
            }
        })
    }

    return {
        featuredResults: featuredResults,
        results: results
    }
}

export const applyDynamicValues = (updatedFilters: IAppliedFilter[]): IAppliedFilter[] => {
    const dynamicValueFilters = updatedFilters.map(filterToCheck => {
        if (filterToCheck.dynamicValueExpression) {
            const dynamicValue = evaluateExpressionWithFilters(filterToCheck.dynamicValueExpression, updatedFilters);

            // Return a new object with the updated value
            return {...filterToCheck, value: dynamicValue};
        }

        // If no dynamicValueExpression, return the filter as-is
        return {...filterToCheck};
    });

    return [...dynamicValueFilters];
};
